<template>
  <div
    class="OfferCard flex"
    :class="{
      'flex-row': dir === 'vertical',
      'flex-col': dir === 'horizontal',
    }"
  >
    <a :href="href ? href : '/'" :aria-label="`navigate to ${href}`">
      <span class="hidden"> navigate to {{ href }} </span>

      <picture>
        <AppSource :srcset="image" format="avif" media="(min-width: 768px)" width="600" height="800" />
        <AppSource :srcset="image" format="webp" media="(min-width: 768px)" width="600" height="800" />
        <AppSource :srcset="image" format="jpeg" media="(min-width: 768px)" width="600" height="800" />

        <AppSource
          v-if="mobileImage"
          :srcset="mobileImage"
          media="(max-width: 767px)"
          format="avif"
          width="375"
          height="500"
        />
        <AppSource
          v-if="mobileImage"
          :srcset="mobileImage"
          media="(max-width: 767px)"
          format="webp"
          width="375"
          height="500"
        />
        <AppSource
          v-if="mobileImage"
          :srcset="mobileImage"
          media="(max-width: 767px)"
          format="jpeg"
          width="375"
          height="500"
        />

        <AppImage
          :src="image"
          :alt="title"
          class="OfferCard__img w-full h-full border border-transparent"
          :fetchpriority="priority"
          loading="lazy"
          width="375"
          height="500"
        />
      </picture>
    </a>

    <div class="OfferCard__content mt-8 mb-14 flex flex-col">
      <slot name="title" />
      <h3
        v-if="title && !$slots.title"
        class="OfferCard__title text-lg-1 font-medium transition-opacity"
        :class="[locale === 'en' ? 'font-display' : 'font-display-ar']"
      >
        {{ title }}
      </h3>
      <slot name="description" />

      <h4
        v-if="description && !$slots.description"
        class="OfferCard__description text-lg-1 font-medium text-left transition-opacity"
      >
        {{ description }}
      </h4>

      <slot name="cta" />
      <a
        v-if="cta && !$slots.cta"
        :href="href"
        class="OfferCard__cta OfferCard__cta--button mt-5 md:mt-8 underline transition-opacity"
      >
        {{ cta }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
const { locale } = useI18n();
defineProps({
  id: {
    type: Number,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  description: {
    type: String,
    required: false,
  },
  cta: {
    type: String,
    required: false,
    default: null,
  },
  image: {
    type: String,
    required: true,
  },
  mobileImage: {
    type: String,
    default: '',
  },
  href: {
    type: String,
    required: true,
  },
  minimal: {
    type: Boolean,
    default: false,
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  priority: {
    type: String,
    default: 'auto',
    validator: (option: string) => {
      return ['high', 'low', 'auto'].includes(option);
    },
  },
  dir: {
    type: String,
    default: 'horizontal',
    validator: (option: string) => {
      return ['horizontal', 'vertical'].includes(option);
    },
  },
});
</script>

<style lang="postcss" scoped>
.swiper-slide {
  .OfferCard {
    width: 191px;
    &__img {
      aspect-ratio: 0.74;
    }
    &__content {
      @apply hidden;
      position: absolute;
      bottom: -180px;
      @screen lg {
        left: -324px; /** 278: txt width + 46 gap*/
        max-width: 278px;
        top: 0;
      }
    }
    @screen lg {
      width: 299px;
      &__img {
        aspect-ratio: 0.73;
      }
    }
  }
}

.swiper-slide-active {
  .OfferCard {
    width: 322px;
    &__content {
      @apply flex;
    }

    @screen lg {
      width: 442px;
    }
  }
}

.swiper-slide-next {
  .OfferCard {
    &__content {
      @apply flex;
      @screen lg {
        @apply hidden;
      }
    }
  }
}
</style>
